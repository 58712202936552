// export default convertToReactFlowData;
export const convertToReactFlowData = function(data) {
  const nodes = [];
  const edges = [];
  let position = { x: 0, y: 0 }; // Starting position for nodes

  Object.entries(data.nodes).forEach(([nodeId, nodeData], index) => {
    // Create a node with type 'storyNode'
    nodes.push({
      id: nodeId,
      type: 'storyNode', // Set the custom node type
      data: { 
        label: nodeData.text, 
        id: nodeId,
        text: nodeData.text,
        data: nodeData.data,
        events: nodeData.events,
        options: nodeData.options,
        // Include other data fields if needed
      },
      position: { x: position.x, y: position.y + 100 * index } // Adjust position for each node
    });

    // Create edges for each option
    nodeData.options?.forEach((option, optionIndex) => {
      const handleId = `${nodeId}-o-${option.text}`; // Unique ID for the handle
      edges.push({
        id: `${nodeId}-${option.text}-${option.target}`,
        source: nodeId,
        sourceHandle: handleId, // Connect edge to the specific handle
        target: option.target,
        type: "straight",
        animated: true,
        label: option.text
      });
    });
  });

  return { nodes, edges };
};

export const convertFromReactFlowData = function(nodes, edges) {
  const jsonData = { nodes: {} };

  nodes.forEach(node => {
    // Extract necessary information from the node
    const { id, data } = node;
    jsonData.nodes[id] = {
      text: data.text,
      data: data.data,
      options: [],
      events: data.events
    };
  });

  edges.forEach(edge => {
    // Find the corresponding node for the edge source
    const sourceNode = jsonData.nodes[edge.source];
    if (sourceNode) {
      // Extract the target and the label from the edge
      const option = {
        text: edge.label,
        target: edge.target
      };
      sourceNode.options.push(option);
    }
  });

  return jsonData;
};


