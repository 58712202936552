//---------------------------Story System Classes -------------------------------------
// StoryNode 
// Use data to store extras data like image and sound for the current Story or the character name
// added events to fire when the current node is reached
export class StoryNode {
    constructor(id, text, data = {}, events = []) {
        this.id = id;
        this.text = text;
        this.options = [];
        this.data = data;
        this.events = events;
    }

    addOption(optionText, targetNode) {
        const index = this.options.length;
        this.options.push({
            index: index, // Auto-incrementing index
            text: optionText,
            target: targetNode
        });
    }

    // Connects an existing option to a new target node
    connectToTarget(optionIndex, targetNode) {
        if (this.options[optionIndex]) {
            this.options[optionIndex].target = targetNode;
        } else {
            console.warn(`Option index ${optionIndex} does not exist.`);
        }
    }

    // Sets new text for the node
    setText(newText) {
        this.text = newText;
    }
}


// StoryTree Class
export class StoryTree {
    constructor(nodes, startNodeId) {
        this.nodes = nodes;
        // record the start node to later be used to reset the whole Story progress
        this.startNodeId = startNodeId;
        this.currentNode = this.nodes[startNodeId];
    }

    getCurrentStoryNode() {
        return this.currentNode;
    }

    selectOption(optionIndex) {
        if (optionIndex >= 0 && optionIndex < this.currentNode.options.length) {
            const selectedOption = this.currentNode.options[optionIndex];
            this.currentNode = this.nodes[selectedOption.target];
            return this.currentNode;
        } else {
            console.error("Invalid option selected.");
            return null;
        }
    }

    // reset the whole Story
    reset() {
        this.currentNode = this.nodes[this.startNodeId];
    }
}


// Parser Class to parse a single diagloue tree
export class StoryTreeParser {
    constructor(jsonData) {
        this.jsonData = jsonData;
    }

    initializeStoryTree() {
        const nodes = {};
        for (let nodeId in this.jsonData.nodes) {
            const nodeData = this.jsonData.nodes[nodeId];
            const node = new StoryNode(nodeId, nodeData.text, nodeData.data, nodeData.events);
            nodes[nodeId] = node;

            for (let option of nodeData.options) {
                node.addOption(option.text, option.target);
            }
        }

        return new StoryTree(nodes, this.jsonData.startNode);
    }
}

// An In-memory Story Database
export class StoryDatabase {

    // Initialize the database from a JSON array
    constructor(jsonArray) {
        this.Storys = {};
        if (jsonArray !== null) {
            jsonArray.forEach(StoryData => {
                const parser = new StoryTreeParser(StoryData);
                const StoryTree = parser.initializeStoryTree(StoryData.startNode);
                this.addStory(StoryData.id, StoryTree);
            });
        }
    }

    addStory(StoryId, Story) {
        if (this.Storys.hasOwnProperty(StoryId)) {
            console.error(`A Story with the ID "${StoryId}" already exists. Not overwriting.`);
            return false;
        }
        this.Storys[StoryId] = Story;
        return true;
    }

    getStory(StoryId) {
        return this.Storys[StoryId];
    }

    // get all the available Story ids
    getStoryIDs() {
        return Object.keys(this.Storys);
    }

}