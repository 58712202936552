import React from 'react';
import StoryEditor from './story-flow/story-editor';

function App() {
  return (
    <div className="App">
      <StoryEditor />
    </div>
  );
}

export default App;