import React, { useCallback, useEffect, useState } from 'react';
import ReactFlow, { Background, addEdge, useEdgesState, useNodesState } from 'reactflow';
import ELK from 'elkjs/lib/elk.bundled.js';
import 'reactflow/dist/style.css';
import './story-editor.css'; // Import the CSS file
import { storyNodeWidth, storyNodeHeight } from './layout-config'; // Import the constants

import story from './sample-data/simple';
import {convertToReactFlowData} from './conversion';
import StoryPlay from './story-play';
import Modal from '@mui/material/Modal';

import { StoryTreeParser } from './story-tree';
import StoryFlowNode from './story-flow-node'; 
import generateStory from './story-generate'

import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import StoryNodeEditor from './story-node-editor';

const elk = new ELK();

const elkOptions = {
  'elk.algorithm': 'layered',
  'elk.layered.spacing.nodeNodeBetweenLayers': '100',
  'elk.spacing.nodeNode': '80',
};

const getLayoutedElements = async (nodes, edges, direction = 'DOWN') => {
  const elkGraph = {
    id: 'root',
    layoutOptions: { ...elkOptions, 'elk.direction': direction },
    children: nodes.map(node => ({
      id: node.id,
      width: storyNodeWidth,
      height: storyNodeHeight,
    })),
    edges: edges.map(edge => ({
      id: edge.id,
      sources: [edge.source],
      targets: [edge.target]
    }))
  };

  const layoutedGraph = await elk.layout(elkGraph);

  const layoutedNodes = layoutedGraph.children.map(node => ({
    ...nodes.find(n => n.id === node.id),
    position: { x: node.x, y: node.y }
  }));

  return { nodes: layoutedNodes, edges };
};

// Define custom node types
const nodeTypes = {
    storyNode: StoryFlowNode, // Assign 'storyNode' type to use StoryFlowNode component
};


function StoryEditor() {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [storyJson, setStoryJson] = useState(JSON.stringify(story, null, 2));
  const [isStoryModalOpen, setIsStoryModalOpen] = useState(false);
  const [currentStoryTree, setCurrentStoryTree] = useState(null);
  const [promptInput, setPromptInput] = useState('');
  const [isStoryLoading, setIsStoryLoading] = useState(false);

  // modal window for node edit
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedNode, setSelectedNode] = useState(null);

  // state to track selected node ID
  const [selectedNodeId, setSelectedNodeId] = useState(null); 

  useEffect(() => {
    const { nodes: initialNodes, edges: initialEdges } = convertToReactFlowData(story);
    getLayoutedElements(initialNodes, initialEdges).then(({ nodes: layoutedNodes, edges: layoutedEdges }) => {
      setNodes(layoutedNodes);
      setEdges(layoutedEdges);
    });

    const initialStoryTree = new StoryTreeParser(story).initializeStoryTree();
    setCurrentStoryTree(initialStoryTree);
  }, [setNodes, setEdges]);

  const onConnect = useCallback(
    (connection) => setEdges((eds) => addEdge(connection, eds)),
    [setEdges]
  );

  const handleVisualizeClick = async() => {
    await visualizeStory();
  };

  const visualizeStory = async function(){
    try {
      const storyData = JSON.parse(storyJson);
      const { nodes: newNodes, edges: newEdges } = convertToReactFlowData(storyData);
      getLayoutedElements(newNodes, newEdges).then(({ nodes: layoutedNodes, edges: layoutedEdges }) => {
        setNodes(layoutedNodes);
        setEdges(layoutedEdges);
      });  
      const updatedStoryTree = new StoryTreeParser(storyData).initializeStoryTree();
      setCurrentStoryTree(updatedStoryTree);
    } catch (error) {
      console.error('Failed to parse JSON:', error);
    }
  }

  const visualizeStoryData = async function(storyData){
    try {
      const { nodes: newNodes, edges: newEdges } = convertToReactFlowData(storyData);
      getLayoutedElements(newNodes, newEdges).then(({ nodes: layoutedNodes, edges: layoutedEdges }) => {
        setNodes(layoutedNodes);
        setEdges(layoutedEdges);
      });  
      const updatedStoryTree = new StoryTreeParser(storyData).initializeStoryTree();
      setCurrentStoryTree(updatedStoryTree);
    } catch (error) {
      console.error('Failed to parse JSON:', error);
    }
  }

  const toggleStoryPlay = () => {
    setIsStoryModalOpen(!isStoryModalOpen); // Toggle modal visibility
  };

  const onStoryEnd = () => {
    setIsStoryModalOpen(false)
  };

  const restartStory = () => {
    if (currentStoryTree) {
      currentStoryTree.reset();
      setIsStoryModalOpen(true)
    }
  };

  // Function to handle input changes
  const handleInputChange = (e) => {
    setPromptInput(e.target.value);
  };

  // Function to handle generate button click
  const handleGenerateClick = () => {
    setIsStoryLoading(true); // Start loading
    generateStory(promptInput, async(error, storyData) => {
      setIsStoryLoading(false);
      if (error) {
        console.error('Error fetching story:', error);
        return;
      }

      // Set the received storyData into the json input area
      const storyDataString = JSON.stringify(storyData, null, 2);
      setStoryJson(storyDataString);
      await visualizeStoryData(storyData);

    });
    
  };

  const onNodeDoubleClick = useCallback((event, node) => {
    setSelectedNode(node);
    setIsDrawerOpen(true);
    
  }, []);

  // Handle node click to update selection
  const onNodeClick = useCallback((event, node) => {
    setSelectedNodeId(node.id);
  }, []);

  const saveNodeData = async(updatedNode) => {
    // Parse the current JSON string to an object
    let tempStoryData = JSON.parse(storyJson);
    // Update the specific node in the story data
    const nodeID = updatedNode.id;
    tempStoryData.nodes[nodeID] = {
        // Add your node updates here
        // Example: text: updatedNode.text
        id:nodeID,
        text: updatedNode.text,
        data: updatedNode.data,
        options: updatedNode.options
      };
    
  
    // Update edges if necessary
    // This part depends on how your edges are structured in the JSON
  
    // Convert the updated story data back to a JSON string
    const updatedStoryJson = JSON.stringify(tempStoryData, null, 2);
    await setStoryJson(updatedStoryJson);

    const updatedStoryTree = new StoryTreeParser(tempStoryData).initializeStoryTree();
    setCurrentStoryTree(updatedStoryTree);

    // Visualize the updated story
    // await visualizeStoryData(updatedStoryTree);
    await visualizeStory();
  };
  

  // Drawer style
  const drawerStyle = {
    width: '400px',
    padding: '0px',
    height: '100%',
    boxSizing: 'border-box',
  };

  return (
    <div style={{ backgroundColor:'#1e272e',display: 'grid', gridTemplateColumns: '1fr 2fr 0fr', height: '100vh' }}>
      {/* First Column: Prompt and JSON input areas */}
      <div style={{ display: 'grid', gridTemplateRows: '35% 65%', padding: '10px',  border: '2px solid #ffda79' }}>
        {/* JSON input area */}
        <div>
          <textarea
            value={storyJson}
            onChange={(e) => setStoryJson(e.target.value)}
            placeholder="Enter story JSON data here"
            style={{backgroundColor:'#f7f1e3', width: '100%', height: '70%' }}
          />
          <button className="visualize-button" onClick={handleVisualizeClick}>
            Visualize
          </button>
        </div>
        
        {/* Prompt input area */}
        <div>
          <textarea
            value={promptInput}
            onChange={handleInputChange}
            placeholder="Enter your prompt here"
            style={{fontSize:'24px',backgroundColor:'#f7f1e3',width: '100%', height: '90%', margin: '0 0 5px 0' }}
          />
          <div>
          {!isStoryLoading && (<button className="generate-button" onClick={handleGenerateClick}>
              Generate
            </button>)}
            {isStoryLoading && ( <div className="spinner"></div>)}
          </div>
        </div>
      </div>
  
      {/* Second Column: ReactFlow container */}
      <div style={{ height: '100%' }}>
        <ReactFlow
          nodes={nodes.map(node => ({
            ...node,
            data: {
              ...node.data,
              selected: node.id === selectedNodeId
            }
          }))}
          onNodeClick={onNodeClick}
          edges={edges}
          nodeTypes={nodeTypes}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onNodeDoubleClick={onNodeDoubleClick}
        >
          <Background color="#ccc" variant="dots" />
        </ReactFlow>

      {/* Drawer */}
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      >
        <Box sx={drawerStyle}>
          {selectedNode && (
            <StoryNodeEditor node={selectedNode.data} onSave={saveNodeData} />
          )}
        </Box>
      </Drawer>

      </div>
  
      {/* Third Column: Buttons and Story Overlay */}
      <div className="story-play-control">
        <button className="play-story-button" onClick={toggleStoryPlay}>
          Play Story
        </button>
  
        <button className="restart-story-button" onClick={restartStory}>
          Restart Story
        </button>
  
        {/* Story Modal */}
        <Modal
          open={isStoryModalOpen}
          onClose={() => setIsStoryModalOpen(false)}
          aria-labelledby="story-modal-title"
          aria-describedby="story-modal-description"
        >
          <Box sx={{ /* change modal styles here */ }}>
            {/* story content here */}
            {currentStoryTree && (
              <StoryPlay
                storyTree={currentStoryTree}
                onStoryEnd={onStoryEnd}
              />
            )}
          </Box>
        </Modal>

      </div>
    </div>
  );
  

}

export default StoryEditor;


