import React, { useState, useEffect } from 'react';
import './story-play.css'; // Import the CSS file

const StoryPlay = ({ storyTree, onStoryEnd }) => {
  const [currentStoryNode, setCurrentStoryNode] = useState(storyTree.getCurrentStoryNode());
  const [currentPage, setCurrentPage] = useState(0);
  const wordsPerPage = 1000; // Adjust as needed

  useEffect(() => {
    // Update the current story node when the storyTree prop changes
    setCurrentStoryNode(storyTree.getCurrentStoryNode());
    setCurrentPage(0); // Reset to the first page
  }, [storyTree]);

  const splitTextIntoPages = (text, charsPerPage) => {
    const pages = [];
    while (text.length > 0) {
      const page = text.substr(0, charsPerPage);
      pages.push(page);
      text = text.substr(charsPerPage);
    }
    return pages;
  };

  const pages = splitTextIntoPages(currentStoryNode.text, wordsPerPage);

  const showPrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const showNextPage = () => {
    if (currentPage < pages.length - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const onOptionSelect = (optionIndex) => {
    const nextStoryNode = storyTree.selectOption(optionIndex);
    if (nextStoryNode) {
      setCurrentStoryNode(nextStoryNode);
      setCurrentPage(0); // Reset page view for new node
    }
  };

  return (
    <div className='story-overlay'>
    <div className="story-container" style={{ backgroundImage: `url(${currentStoryNode.data.image})` }}>
      <div className="story-character-name">{currentStoryNode.data.characterName}</div>
      <div className="story-text">{pages[currentPage]}</div>
      <div className="story-navigation">
        {currentPage > 0 && <button className="prev-page" onClick={showPrevPage}>&lt;&lt;</button>}
        {currentPage < pages.length - 1 && <button className="next-page" onClick={showNextPage}>&gt;&gt;</button>}
      </div>
      <div className="story-options">
        {currentStoryNode.options.map((option, index) => (
          <button key={index} onClick={() => onOptionSelect(index)}>
            {option.text}
          </button>
        ))}
      </div>
      {currentStoryNode.options.length === 0 && <button className="end-story" onClick={onStoryEnd}>Good Bye!</button>}
    </div>
    </div>
  );
};

export default StoryPlay;
