import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';
import './story-flow-node.css'; // Import the CSS file
import { storyNodeWidth, storyNodeHeight } from './layout-config'; // Import the constants

const StoryFlowNode = memo(({ data, isConnectable, selected }) => {

  // Calculate handle positions
  const calculateHandlePosition = (index, total) => {
    if (total === 1) return '50%';
    return `${((index + 1) / (total + 1)) * 100}%`;
  };

  // Determine the class name based on whether the node is selected
  const nodeClassName = `story-flow-node${selected ? ' selected' : ''}`;


  return (
    <div className={nodeClassName} style={{ width: `${storyNodeWidth}px`, height: `${storyNodeHeight}px` }}>
      <Handle
        type="target"
        position={Position.Top}
        style={{ background: '#555' }}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />
      <div className="story-node">
        <div className="story-node-id">
          <strong>{data.id}</strong>
        </div>
        <div className="story-text-container" title={data.text}>

            <span>{data.text}</span>
        </div>
      </div>
      {data.options.map((option, index) => (
        <Handle
          key={option.target + index}
          type="source"
          position={Position.Bottom}
          id={`${data.id}-o-${option.text}`} // Consistent handle ID pattern
          style={{ background: '#555', left: calculateHandlePosition(index, data.options.length) }}
          isConnectable={isConnectable}
        />
      ))}
    </div>
  );
});

export default StoryFlowNode;
