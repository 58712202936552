import React, { useState } from 'react';
import './story-node-editor.css'; // Import the CSS file

const StoryNodeEditor = function({ node, onSave }) {
    const [id, setId] = useState(node.id);
    const [text, setText] = useState(node.text);
    const [data, setData] = useState(JSON.stringify(node.data, null, 2));
    const [options, setOptions] = useState(node.options);

    const handleOptionChange = (index, field, value) => {
        const updatedOptions = options.map((option, idx) => {
            if (idx === index) {
                return { ...option, [field]: value };
            }
            return option;
        });
        setOptions(updatedOptions);
    };

    const addOption = () => {
        setOptions([...options, { index: options.length, text: '', target: '' }]);
    };

    const removeOption = (index) => {
        setOptions(options.filter((_, idx) => idx !== index));
    };

    const saveNode = () => {
        const updatedNode = {
            id,
            text,
            data: JSON.parse(data),
            options
        };
        onSave(updatedNode);
    };

    return (
        <div className='story-node-editor'>
            <div className='editor-field'>
                <label className='editor-label'>ID:</label>
                <input 
                    type="text" 
                    className='editor-input' 
                    value={id} 
                    onChange={(e) => setId(e.target.value)} 
                />
            </div>
            <div className='editor-field'>
                <label className='editor-label'>Text:</label>
                <textarea 
                    className='editor-textarea' 
                    value={text} 
                    onChange={(e) => setText(e.target.value)} 
                />
            </div>
            <div className='editor-field-json'>
                <label className='editor-label'>Data (JSON):</label>
                <textarea 
                    className='editor-textarea-json' 
                    value={data} 
                    onChange={(e) => setData(e.target.value)} 
                />
            </div>
            <div className='editor-options'>
                <label className='editor-label'>Options:</label>
                {options.map((option, index) => (
                    <div key={index} className='option-item'>
                        <input 
                            type="text" 
                            className='option-input' 
                            value={option.text} 
                            onChange={(e) => handleOptionChange(index, 'text', e.target.value)} 
                        />
                        <input 
                            type="text" 
                            className='option-input' 
                            value={option.target} 
                            onChange={(e) => handleOptionChange(index, 'target', e.target.value)} 
                        />
                        <button 
                            className='option-remove-btn' 
                            onClick={() => removeOption(index)}
                        >
                            Remove
                        </button>
                    </div>
                ))}
                <button className='option-add-btn' onClick={addOption}>Add Option</button>
            </div>
            <button className='save-node-btn' onClick={saveNode}>Save Node</button>
        </div>
    );
};

export default StoryNodeEditor;
