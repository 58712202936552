const story = {
    "id": "treeAdventure",
    "startNode": "startPoint",
    "nodes": {
        "startPoint": {
            "text": "You find yourself in a clearing in a dense forest. Two paths stretch out before you.",
            "data": {
                "characterName": "Clay",
                "image": "forest_clearing.png",
                "sound": "forest_ambience.mp3"
            },
            "options": [
                {
                    "text": "Follow the path lined with stones.",
                    "target": "stonePath"
                },
                {
                    "text": "Walk along the mossy trail.",
                    "target": "mossyTrail"
                }
            ],
            "events": []
        },
        "stonePath": {
            "text": "The stone path leads to an old bridge over a quiet stream.",
            "data": {
                "characterName": "Clay",
                "image": "old_bridge.png",
                "sound": "stream.mp3"
            },
            "options": [
                {
                    "text": "Cross the bridge.",
                    "target": "crossBridge"
                }
            ],
            "events": []
        },
        "mossyTrail": {
            "text": "You walk along the mossy trail and arrive at a small pond with luminous fish.",
            "data": {
                "characterName": "Clay",
                "image": "pond_fish.png",
                "sound": "water.mp3"
            },
            "options": [
                {
                    "text": "Sit by the pond.",
                    "target": "sitPond"
                }
            ],
            "events": []
        },
        "crossBridge": {
            "text": "Across the bridge, you discover a hidden garden with exotic flowers.",
            "data": {
                "characterName": "Clay",
                "image": "hidden_garden.png",
                "sound": "birds.mp3"
            },
            "options": [
                {
                    "text": "Explore the garden.",
                    "target": "exploreGarden"
                }
            ],
            "events": []
        },
        "sitPond": {
            "text": "Sitting by the pond, you feel a sense of peace and tranquility.",
            "data": {
                "characterName": "Clay",
                "image": "peaceful_pond.png",
                "sound": "nature_ambience.mp3"
            },
            "options": [
                {
                    "text": "Continue exploring the forest.",
                    "target": "exploreForest"
                }
            ],
            "events": []
        },
        "exploreGarden": {
            "text": "The garden reveals a variety of rare plants and a small, hidden cottage.",
            "data": {
                "characterName": "Clay",
                "image": "cottage_garden.png",
                "sound": "rustling_leaves.mp3"
            },
            "options": [],
            "events": []
        },
        "exploreForest": {
            "text": "Deeper in the forest, you find an ancient tree with carvings on its trunk.",
            "data": {
                "characterName": "Clay",
                "image": "ancient_tree.png",
                "sound": "wind_in_trees.mp3"
            },
            "options": [],
            "events": []
        }
    }
}

export default story;